import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import { IconImage } from "../../common/IconImage";
import Container from "../../common/Container";

import {
  FooterSection,
  NavLink,
  LogoContainer,
  FooterContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between" align="middle">
            <Col lg={7} md={8} sm={12} xs={12}>
              <NavLink to="/">
                <LogoContainer>
                  <IconImage src="logo192.png" width="40px" height="40px" />
                  <SvgIcon src="logo.svg" width="250px" height="70px" />
                </LogoContainer>
              </NavLink>
            </Col>

            <Col lg={7} md={8} sm={12} xs={12}>
              <FooterContainer>
                <SocialLink
                  href="https://github.com/scheduled-webhook"
                  src="github.svg"
                />
                <SocialLink
                  href="https://twitter.com/ScheduleWebhook"
                  src="twitter.svg"
                />
                <SocialLink
                  href="https://www.linkedin.com/in/scheduled-webhook"
                  src="linkedin.svg"
                />
              </FooterContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
