import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import React from 'react';
import Container from '../../common/Container';
const DashboardHeader = lazy(() => import('../DashboardHeader'));
const LoadingRocket = lazy(() => import('../LoadingRocket'));

interface Props {
    child: any;
  }

const AuthGuard = ({child}: Props) => {
    const history = useHistory();
    const [authUser, loadingAuth] = useAuthState(auth);
    if (loadingAuth === false && authUser == null) {
        history.replace('/')
    }

    return (
        <Container width={'100%'} >
            <DashboardHeader />
            <div style={{ height: '75vh', width: '100%' }}>
                {loadingAuth ? <LoadingRocket /> : React.cloneElement(child, { authUser }) }
            </div>
            <div style={{ flex: '1 1 auto' }}></div>
        </Container>
    );
};

export default AuthGuard;
