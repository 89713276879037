const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    guard: false,
  },
  {
    path: ["/signup"],
    exact: true,
    component: "Signup",
    guard: false,
  },
  {
    path: ["/dashboard"],
    exact: true,
    component: "Dashboard",
    guard: true,
  },
];

export default routes;
